import { useState, useEffect } from 'react';

const useFetch = (url, method, dataToSend) => {

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    // Runs everytime the component is rendered
    useEffect(() => {

        //Aborts loading when unmounted
        const abortCont = new AbortController();

        const fetchOptions = {
         signal: abortCont.signal
        };

        // Add POST method and body
        if(method === "POST") {
            fetchOptions.headers = {'Content-Type': 'application/json'};
            fetchOptions.method = "POST";
            fetchOptions.body = dataToSend;
        }
        

        //console.log('use effect ran'); 
        fetch(url, fetchOptions)
        .then(res => {
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }
             return res.json();
        })
        .then((data) => {
             setData(data);
             setIsPending(false);
             setError(null);
        })
        .catch((err) => {
         if(err.name === 'AbortError') {
            console.log('fetch aborted')
         } else {
            setIsPending(false);
            setError(err.message);
         }
        })

        // Aborts useEffect when unmounted
        return () => abortCont.abort();

     }, [url, method, dataToSend]);

     return { data, isPending, error }
}

export default useFetch;