import { useEffect, useState } from 'react';
import PageLayout from '../../Components/layouts/PageLayout/PageLayout';
import styles from '../../Styles/Series.module.css';
import SeriesOverview from "../../Components/SeriesOverview/SeriesOverview";

const ImportSeries = () => {
    let error = null;
    const [ isPending, setIsPending ] = useState(true);
    const [ fileData, setFileData ] = useState(null);
    const [ apiData, setApiData ] = useState(null);
    const [ data, setData ] = useState(null);

    // Get initial data
    useEffect(() => {

        // Setting up calls to local file
        const filePath = '/JSON/iRacingData/2023-10-29-series-seasons.json';
        const fileRequest = fetch(filePath).then(response => response.json());

        // Setting up calls to DB
        const apiPath = process.env.REACT_APP_API_URL+'api/ir-series-seasons';
        const apiRequest = fetch(apiPath).then(response => response.json());

        // fetch data from DB and file
        const dataFetch = async () => {
            
            Promise.all([fileRequest, apiRequest])
                .then(([fileResponse, apiResponse]) => {
                    
                    // Update the Data with the response
                    setFileData(fileResponse);
                    setApiData(apiResponse);
                })
                .then(() => {
                    setIsPending(false);
                })
                .catch(error => {
                    console.error(error);
                })
        };
        dataFetch()
    }, []);



    const saveSeriesClickHandler = (event,seriesObj) => {
        //console.log("seriesObj: ",seriesObj);

        //remove added elements to data
        delete seriesObj.foundEntry;
        
        fetch(process.env.REACT_APP_API_URL+'api/ir-series-seasons',{
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(seriesObj)
        })
        .then(res => {
         
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }

            // Change the button to Text
            event.target.innerHTML="Saved";
            // Disable the button
            event.target.disabled = true;
             
             return res.json();
        })
        .catch((err) => {
            console.log(err.message);
         
        })  
    }

    // Update main data when fileData or apiData change
    useEffect(() => {
        if(fileData) {
            // Find if this file data has a matching database entry
            fileData.forEach((fileSeries, fileIndex) => {
                // Set initial value to false
                fileData[fileIndex].foundEntry = null;

                // Loop through all DB Cars
                apiData.forEach((apiSeries, apiIndex) => {
                    
                    // Check for match
                    if(fileSeries.season_id === apiSeries.season_id) {
                        fileData[fileIndex].foundEntry = apiIndex;
                        return;
                    }
                })
            })

            setData(fileData);
        }

    },[fileData, apiData]);
   

    return (
        <PageLayout
            title='Import iRacing Series'
            description='Imports series data from iRacing JSON files into IRB Database'
        >
            
            {isPending && <div>Loading...</div>}
            {error && <div>{ error }</div>}
            {data && data
                .sort((a,b) => a.season_name.localeCompare(b.season_name))
                .map((series) => {
                    return (
                        <div className={ styles.row } key={series.season_id}>
                            <SeriesOverview obj={ series } />
                            <div>
                                {series.foundEntry !=null && <span>In Database</span>}<br />
                                {series.foundEntry === null && <button className={ styles.importButton } onClick={ (e)=> saveSeriesClickHandler(e,series) }>Save Series to Database</button>}
                            </div>
                        </div>
                    );
                })
            }
        </PageLayout>
    );
}
 
export default ImportSeries;