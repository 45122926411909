import CarClassOverview from "../../Components/CarClassOverview/CarClassOverview";
import PageLayout from '../../Components/layouts/PageLayout/PageLayout';
import styles from '../../Styles/Cars.module.css';
import useCompareData from '../../Services/useCompareData';

const ImportCarClass = () => {
    const apiPath = process.env.REACT_APP_API_URL+'api/ir-carclass';
    const filePath = '/JSON/iRacingData/2023-11-10-carclass.json';
    const sortProperty = 'name';
    const uniqueId = 'car_class_id';
    const { data, isPending, error } = useCompareData(filePath, apiPath, uniqueId);
    

    // Button clicked to save data to DB
    const saveDataClickHandler = (event, rowObj) => {

        //remove added elements to data
        delete rowObj.foundEntry;
        
        fetch(apiPath, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(rowObj)
        })
        .then(res => {
         
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }

            // Change the button to Text
            event.target.innerHTML="Saved";
            // Disable the button
            event.target.disabled = true;
            
             return res.json();
        })
        .catch((err) => {
            console.log(err.message);
        })  
    }

    return (

        <PageLayout
            title='Import iRacing Car Class'
            description='Imports car class data from iRacing JSON files into IRB Database'
        >
            {isPending && <div>Loading...</div>}
            {error && <div>{ error }</div>}
            {data && data
                .sort((a,b) => a[sortProperty].localeCompare(b[sortProperty]))
                .map((dataObj) => {
                    return (
                        <div className={ styles.row } key={dataObj[uniqueId]}>
                            <CarClassOverview obj={dataObj} />
                            <div>
                                {dataObj.foundEntry !=null && <span>In Database</span>}<br />
                                {dataObj.foundEntry === null && <button className={ styles.importButton } onClick={ (e)=> saveDataClickHandler(e, dataObj) }>Save to Database</button>}
                            </div>
                        </div>
                    );
                })
            }
        </PageLayout>
    );
}
 
export default ImportCarClass;