import { useState } from 'react';
import SeasonOverview from "../Components/SeasonOverview/SeasonOverview";
import useFetch from '../Services/useFetch';
import styles from "../Styles/Seasons.module.css";
import PageLayout from "../Components/layouts/PageLayout/PageLayout";

export default function Seasons() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/season');
    const [filters, setFilters] = useState(null);

    const filterClickHandler = (e) => {
        if(e.target.innerHTML === 'All'){
            setFilters(null);
        }else{
            setFilters(e.target.innerHTML);
        } 
    }

    const filterData = (season) => {
        if(filters != null) {
            return season.type === filters;
        }else{
            return season;
        }
    }

    return (
        <div className={ styles.seasons }>
            <PageLayout 
                title='Seasons'
                description='Offline versions of official series, historic recreation, and challenges'
            >
                {isPending && <div className={ styles.dataLoading }>Loading...</div>}
                {error && <div className={ styles.dataError }>{ error }</div>}
                {data && 
                    <div className={ styles.seasonFilters }>
                        <button onClick={ filterClickHandler }>All</button>
                        <button onClick={ filterClickHandler }>Oval</button>
                        <button onClick={ filterClickHandler }>Road</button>
                    </div>
                }
                <div className={ styles.seasonsWrapper }>
                    {data && data
                        .filter(filterData)
                        .map((season) => {
                        return (
                            <SeasonOverview key={season._id} obj={season} />
                        );
                    })}
                </div>
            </PageLayout>
        </div>
    )
}