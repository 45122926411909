import { useState, useEffect } from 'react';

const useCompareData = (filePath, apiPath, uniqueId) => {
    const [ data, setData ] = useState(null);
    const [ fileData, setFileData ] = useState(null);
    const [ apiData, setApiData ] = useState(null);
    const [ isPending, setIsPending ] = useState(true);
    const [ error, setError ] = useState(null);

    // Runs everytime the component is rendered
    useEffect(() => {

        // Setting up calls to local file
        const fileRequest = fetch(filePath).then(response => response.json());

        // Setting up calls to DB
        const apiRequest = fetch(apiPath).then(response => response.json());

        //Aborts loading when unmounted
        const abortCont = new AbortController();

        // fetch data from DB and file
        Promise.all([fileRequest, apiRequest])
            .then(([fileResponse, apiResponse]) => {
                
                // Update the Data with the response
                setFileData(fileResponse);
                setApiData(apiResponse);
            })
            .then(() => {
                setIsPending(false);
            })
            .catch(err => {
                if(err.name === 'AbortError') {
                    console.log('Fetch Aborted!')
                }else {
                    setIsPending(false);
                    setError(err.message);
                }
            })

            // Aborts useEffect when unmounted
            return () => abortCont.abort();

     }, [filePath, apiPath]);

    // Update main data when fileData or apiData change
    useEffect(() => {

        if(fileData) {
            // Find if this file data has a matching database entry
            fileData.forEach((fileObj, fileIndex) => {
                // Set initial value to false
                fileData[fileIndex].foundEntry = null;

                // Loop through all DB Entries
                apiData.forEach((apiObj, apiIndex) => {
                    
                    // Check for match
                    if(fileObj[uniqueId] === apiObj[uniqueId]) {
                        fileData[fileIndex].foundEntry = apiIndex;
                        return;
                    }
                })
            })

            setData(fileData);
        }

    },[fileData, apiData, uniqueId]);

     return { data, isPending, error }
}

export default useCompareData;