import React from 'react'
import PageLayout from '../Components/layouts/PageLayout/PageLayout';

export default function Faqs() {
    return (
        <PageLayout
            title='FAQs'
            description='Awnsers for how to use the iRacing AI files'
        ></PageLayout>
    )
}