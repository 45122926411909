import styles from './PageLayout.module.css';

const PageIntro = (props) => {
    return (
        <>
        <div className={ styles.introWrapper }>
            <section className={ styles.pageIntro }>
                <div className={ styles.pageIntroContent }>
                    <h1>{ props.title }</h1>
                    <p>{ props.description }</p> 
                </div>
            </section>
        </div>
        <div className={ styles.pageContent }>{ props.children }</div>
        </>
    );
}
 
export default PageIntro;