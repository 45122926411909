import React, { useState } from 'react'
import styles from './/SeriesOverview.module.css';

export default function SeriesOverview(props) {
    const [detailsDisplayed, setDetailsDisplayed] = useState(false);

    function handleRowClick() {
        setDetailsDisplayed(!detailsDisplayed);
    }

    return (
        <div className={ styles.seriesOverviewWrapper }>   
            
            <div className={ styles.seriesName }>
                <button  onClick={ handleRowClick }>
                    { props.obj.season_name }
                </button>
            </div>
        
            { detailsDisplayed && (
                <div className={ styles.detailsWrapper }>
                    <div>Id: { props.obj.season_id }</div>                    
                </div>
            ) }
        </div>
    )
}