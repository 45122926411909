import React from 'react'
import PageLayout from '../Components/layouts/PageLayout/PageLayout'

export default function Home() {
    return (
        <PageLayout
            title='Repository for iRacing AI files'
            description='Providing AI seasons and rosters for racing offline'
        ></PageLayout>
    )
}