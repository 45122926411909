import React, { useState } from 'react'
import styles from './CarOverview.module.css';

export default function CarOverview(props) {
    const [detailsDisplayed, setDetailsDisplayed] = useState(false);

    function handleRowClick() {
        setDetailsDisplayed(!detailsDisplayed);
    }

    return (
        <div className={ styles.carOverviewWrapper }>   
            
            <div className={ styles.carName }>
                <button  className= { props.obj.ai_enabled ? styles.aiEnabled : null } onClick={ handleRowClick }>
                    { props.obj.car_name }
                </button>
            </div>
        
            { detailsDisplayed && (
                <div className={ styles.detailsWrapper }>
                    <div>Id: { props.obj.car_id }</div>
                    <div>Created: { props.obj.created }</div>
                    <div>Free: { props.obj.free_with_subscription.toString() }</div>
                    <div>Multiple Tires: { props.obj.has_multiple_dry_tire_types.toString() }</div>
                    <div>Weight: { props.obj.car_weight }</div>
                    <div>HP: { props.obj.hp }</div>
                    <div>
                        Categories: 
                        { 
                            props.obj.categories.map((item, i, arr) => (
                                <span key={ i }>{ (i ? ', ' : '') + item }</span>
                            )) 
                        }
                    </div>
                    <div>
                        Types: 
                        { 
                            props.obj.car_types.map((item, i, arr) => (
                                <span key={ i }>{ (i ? ', ' : '') + item.car_type }</span>
                            )) 
                        }
                    </div>
                    
                </div>
            ) }
        </div>
    )
}