import { useState } from 'react';
import useFetch from '../../Services/useFetch';
import styles from "../../Styles/Tracks.module.css";
import PageLayout from "../../Components/layouts/PageLayout/PageLayout";
import TrackOverview from "../../Components/TrackOverview/TrackOverview";

export default function Tracks() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/ir-track');
    const [filters, setFilters] = useState(null);

    const filterClickHandler = (e) => {
        if(e.target.innerHTML === 'All'){
            setFilters(null);
        }else{
            setFilters(e.target.innerHTML.toLowerCase());
        } 
    }

    const filterData = (track) => {
        if(filters != null) {
            return track.category === filters;
        }else{
            return track;
        }
    }

    return (
        <div className={ styles.seasons }>
            <PageLayout 
                title='Tracks'
                description='All tracks in the database'
            >
                {isPending && <div className={ styles.dataLoading }>Loading...</div>}
                {error && <div className={ styles.dataError }>{ error }</div>}
                {data && 
                <>
                    <div className={ styles.trackFilters }>
                        <button onClick={ filterClickHandler }>All</button>
                        <button onClick={ filterClickHandler }>Oval</button>
                        <button onClick={ filterClickHandler }>Road</button>
                    </div>
                    <div className={ styles.legendWrapper }>
                        <div className={ styles.legendItem }>
                            <div className={ styles.legendColor }></div>
                            <div className={ styles.legendLabel }>AI Enabled</div>
                        </div>
                    </div>
                </>
                }
                <div className={ styles.tracksWrapper }>
                    {data && data
                        .sort((a,b) => a.track_name.localeCompare(b.track_name))
                        .filter(filterData)
                        .map((track) => {
                        return (
                            <div className={ styles.row } key={track._id}>
                                <TrackOverview key={track._id} obj={track} />
                            </div>
                        );
                    })}
                </div>
            </PageLayout>
        </div>
    )
}