import React, { useState } from 'react'
import styles from './CarClassOverview.module.css';

export default function CarClassOverview(props) {
    const [detailsDisplayed, setDetailsDisplayed] = useState(false);

    function handleRowClick() {
        setDetailsDisplayed(!detailsDisplayed);
    }

    return (
        <div className={ styles.carClassOverviewWrapper }>   
            
            <div className={ styles.carClassName }>
                <button  onClick={ handleRowClick }>
                    { props.obj.short_name }
                </button>
            </div>
        
            { detailsDisplayed && (
                <div className={ styles.detailsWrapper }>
                    <div>car_class_id: { props.obj.car_class_id }</div>
                    <div>cust_id: { props.obj.cust_id }</div>
                    <div>name: { props.obj.name.toString() }</div>
                    <div>relative_speed: { props.obj.relative_speed }</div>
                    <div>short_name: { props.obj.short_name }</div>
                    <div>
                        cars_in_class: 
                        { 
                            props.obj.cars_in_class.map((item, i, arr) => (
                                <div key={ i }>
                                    <div>car_dirpath: { item.car_dirpath }</div>
                                    <div>car_id: { item.car_id }</div>
                                    <div>retired: { item.retired.toString() }</div>
                                </div>
                            )) 
                        }
                    </div>                    
                </div>
            ) }
        </div>
    )
}