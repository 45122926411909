import CarOverview from "../../Components/CarOverview/CarOverview";
import { useEffect, useState } from 'react';
import PageLayout from '../../Components/layouts/PageLayout/PageLayout';
import styles from '../../Styles/Cars.module.css';

const ImportCars = () => {

    let error = null;
    const [ isPending, setIsPending ] = useState(true);
    const [ fileData, setFileData ] = useState(null);
    const [ apiData, setApiData ] = useState(null);
    const [ data, setData ] = useState(null);

    // Get initial data
    useEffect(() => {

        // Setting up calls to local file
        const filePath = '/JSON/iRacingData/2023-10-29-cars.json';
        const fileRequest = fetch(filePath).then(response => response.json());

        // Setting up calls to DB
        const apiPath = process.env.REACT_APP_API_URL+'api/ir-car';
        const apiRequest = fetch(apiPath).then(response => response.json());

        // fetch data from DB and file
        const dataFetch = async () => {
            Promise.all([fileRequest, apiRequest])
                .then(([fileResponse, apiResponse]) => {
                    
                    // Update the Data with the response
                    setFileData(fileResponse);
                    setApiData(apiResponse);
                })
                .then(() => {
                    setIsPending(false);
                })
                .catch(error => {
                    console.error(error);
                })
        };
        dataFetch()
    }, []);

    // Update main data when fileData or apiData change
    useEffect(() => {

        if(fileData) {
            // Find if this file data has a matching database entry
            fileData.forEach((fileCar, fileIndex) => {
                // Set initial value to false
                fileData[fileIndex].foundEntry = null;

                // Loop through all DB Cars
                apiData.forEach((apiCar, apiIndex) => {
                    
                    // Check for match
                    if(fileCar.car_id === apiCar.car_id) {
                        fileData[fileIndex].foundEntry = apiIndex;
                        return;
                    }
                })
            })

            setData(fileData);
        }

    },[fileData, apiData]);

    // Button clicked to save data to DB
    const saveCarClickHandler = (event,carObj) => {
        //console.log("carObj: ",carObj);

        //remove added elements to data
        delete carObj.foundEntry;
        
        fetch(process.env.REACT_APP_API_URL+'api/ir-car',{
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(carObj)
        })
        .then(res => {
         
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }

            // Change the button to Text
            event.target.innerHTML="Saved";
            // Disable the button
            event.target.disabled = true;
            
             return res.json();
        })
        .catch((err) => {
            console.log(err.message);
         
        })  
    }

    return (

        <PageLayout
            title='Import iRacing Cars'
            description='Imports car data from iRacing JSON files into IRB Database'
        >
            
            {isPending && <div>Loading...</div>}
            {error && <div>{ error }</div>}
            {data && data
                .sort((a,b) => a.car_name.localeCompare(b.car_name))
                .map((car) => {
                    return (
                        <div className={ styles.row } key={car.car_id}>
                            <CarOverview obj={car} />
                            <div>
                                {car.foundEntry !=null && <span>In Database</span>}<br />
                                {car.foundEntry === null && <button className={ styles.importButton } onClick={ (e)=> saveCarClickHandler(e,car) }>Save Car to Database</button>}
                            </div>
                        </div>
                    );
                })
            }
        </PageLayout>
    );
}
 
export default ImportCars;