import React from 'react'
import styles from "./Footer.module.css";

export default function Footer() {
    return (
        <div className={ styles.footerWrapper }>
            <section className={ styles.footerContent }>
                Created by: Apex Pixel 
            </section>
        </div>
    )
}