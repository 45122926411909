import { useEffect, useState } from 'react';
import PageLayout from '../../Components/layouts/PageLayout/PageLayout';
import styles from '../../Styles/Tracks.module.css';
import TrackOverview from "../../Components/TrackOverview/TrackOverview";

const ImportTracks = () => {

    let error = null;
    const [ isPending, setIsPending ] = useState(true);
    const [ fileData, setFileData ] = useState(null);
    const [ apiData, setApiData ] = useState(null);
    const [ data, setData ] = useState(null);

    // Get initial data
    useEffect(() => {

        // Setting up calls to local file
        const filePath = '/JSON/iRacingData/2023-10-29-tracks.json';
        const fileRequest = fetch(filePath).then(response => response.json());

        // Setting up calls to DB
        const apiPath = process.env.REACT_APP_API_URL+'api/ir-track';
        const apiRequest = fetch(apiPath).then(response => response.json());

        // fetch data from DB and file
        const dataFetch = async () => {
            
            Promise.all([fileRequest, apiRequest])
                .then(([fileResponse, apiResponse]) => {
                    
                    // Update the Data with the response
                    setFileData(fileResponse);
                    setApiData(apiResponse);
                })
                .then(() => {
                    setIsPending(false);
                })
                .catch(error => {
                    console.error(error);
                })
        };
        dataFetch()
    }, []);
   
    // Update main data when fileData or apiData change
    useEffect(() => {
        if(fileData) {
            // Find if this file data has a matching database entry
            fileData.forEach((fileTrack, fileIndex) => {
                // Set initial value to false
                fileData[fileIndex].foundEntry = null;

                // Loop through all DB Cars
                apiData.forEach((apiTrack, apiIndex) => {
                    
                    // Check for match
                    if(fileTrack.track_id === apiTrack.track_id) {
                        fileData[fileIndex].foundEntry = apiIndex;
                        return;
                    }
                })
            })

            setData(fileData);
        }

    },[fileData, apiData]);


    const saveTrackClickHandler = (event,trackObj) => {
        //console.log(trackObj);
        
        fetch(process.env.REACT_APP_API_URL+'api/ir-track',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(trackObj)
        })
        .then(res => {
         
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }

            // Change the button to Text
            event.target.innerHTML="Saved";
            // Disable the button
            event.target.disabled = true;
             
             return res.json();
        })
        .catch((err) => {
            console.log(err.message);
         
        })
        
    }

    return (

        <PageLayout
            title='Import iRacing Tracks'
            description='Imports track data from iRacing JSON files into IRB Database'
        >           
            
            {isPending && <div>Loading...</div>}
            {error && <div>{ error }</div>}
            {data && data
                .sort((a,b) => a.track_name.localeCompare(b.track_name))
                .map((track) => {
                    return (
                        <div className={ styles.row } key={ track.track_id }>
                            <TrackOverview obj={ track } />
                            <div>
                                {track.foundEntry !=null && <span>In Database</span>}<br />
                                {track.foundEntry === null && <button className={ styles.importButton } onClick={ (e)=> saveTrackClickHandler(e,track) }>Save Track to Database</button>}
                            </div>
                        </div>
                    );
                })
            }
        </PageLayout>
    );
}
 
export default ImportTracks;