import React from 'react'
import styles from './/SeasonOverview.module.css';

export default function SeasonOverview(props) {
    return (
        <div className={ styles.seasonOverviewWrapper }>
            <div className={ styles.seasonTime }>{props.obj.year +' ' + props.obj.qtr}</div>
            <div className={ styles.seasonName }>{props.obj.name}</div>
            <div className={ styles.seasonSetups }>{props.obj.setup}</div>
            <div className={ styles.seasonType }>{props.obj.type}</div>
            <div className={ styles.seasonClass }>{props.obj.class}</div>
            <div className={ styles.seasonVehicle }>{props.obj.car}</div>
            <div className= { styles.seasonDownload }>
                {props.obj.file && <a className= { styles.seasonDownload } download href={'/JSON/seasons/' + props.obj.file}>Download File</a>}
            </div>
        </div>
    )
}