import React, { useState } from 'react'
import styles from './TrackOverview.module.css';

export default function TrackOverview(props) {
    const [detailsDisplayed, setDetailsDisplayed] = useState(false);

    function handleRowClick() {
        setDetailsDisplayed(!detailsDisplayed);
    }
    //console.log('Tracks: ', props);

    return (
        <div className={ styles.trackOverviewWrapper }>   
            <div className={ styles.trackName }>
                <button  className= { props.obj.ai_enabled ? styles.aiEnabled : null } onClick={ handleRowClick }>
                    { props.obj.track_name } { props.obj.config_name ? ' - ' + props.obj.config_name : null }
                </button>
            </div>
            { detailsDisplayed && (
                <div className={ styles.detailsWrapper }>
                    <div>Package Id: { props.obj.package_id }</div>
                    <div>Id: { props.obj.track_id }</div>
                    <div>Category: { props.obj.category }</div>
                </div>
            )}
        </div>
    )
}

