import React from 'react'
import { Link } from "react-router-dom";
import styles from './/Header.module.css';

export default function Header() {
    return (
        <div className={ styles.headerWrapper }>
            <section className={ styles.headerContent }>
                <h2>iRaceBots</h2>
                <nav>
                    <Link to="/">Home</Link>
                    <Link to="rosters">Rosters</Link>
                    <Link to="seasons">Seasons</Link>
                    <Link to="faqs">FAQs</Link>
                    <Link to="admin">Admin</Link>
                </nav>
            </section>
        </div>
    )
}