import PageLayout from '../../Components/layouts/PageLayout/PageLayout';
import { Link } from "react-router-dom";
import styles from "../../Styles/Admin.module.css";

const Admin = () => {

    return (

        <PageLayout
            title='Admin'
            description='Manage data'
        >
        <nav className={ styles.adminNavWrapper }>
            <Link to="import-cars">Import iRacing Cars</Link>
            <Link to="import-car-class">Import iRacing Car Class</Link>
            <Link to="import-series">Import iRacing Series</Link>
            <Link to="import-tracks">Import iRacing Tracks</Link>
            <Link to="tracks">View Tracks</Link>
            <Link to="cars">View Cars</Link>
        </nav>
        </PageLayout>
    );
}
 
export default Admin;