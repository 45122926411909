import React from 'react'
import PageLayout from '../Components/layouts/PageLayout/PageLayout';

export default function Season() {
    return (
        <PageLayout
            title='Single Season Info'
            description='Info for a single season goes here. Probably need another layout'
        ></PageLayout>
    )
}