import { BrowserRouter, Route, Routes } from "react-router-dom";
import Admin from "./Pages/admin/admin";
import Home from "./Pages/home";
import ImportCars from "./Pages/admin/importCars";
import ImportCarClass from "./Pages/admin/importCarClass";
import ImportSeries from "./Pages/admin/importSeries";
import ImportTracks from "./Pages/admin/importTracks";
import Tracks from "./Pages/admin/tracks";
import Cars from "./Pages/admin/cars";
import Rosters from "./Pages/rosters";
import Seasons from "./Pages/seasons";
import Season from "./Pages/season";
import Faqs from "./Pages/faqs";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import "./Styles/app.css";

function App() {
  return (
    <div id="app"> 
      <BrowserRouter>
        <Header />
        <div id="main">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="admin/tracks" element={<Tracks />} />
            <Route exact path="admin/cars" element={<Cars />} />
            <Route exact path="admin/import-cars" element={<ImportCars />} />
            <Route exact path="admin/import-car-class" element={<ImportCarClass />} />
            <Route exact path="admin/import-series" element={<ImportSeries />} />
            <Route exact path="admin/import-tracks" element={<ImportTracks />} />
            <Route exact path="admin" element={<Admin />} />
            <Route exact path="rosters" element={<Rosters />} />
            <Route path="/season/:seasonId" element={<Season />} />
            <Route exact path="seasons" element={<Seasons />} />
            <Route exact path="faqs" element={<Faqs />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
