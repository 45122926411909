import React from 'react'
import PageLayout from '../Components/layouts/PageLayout/PageLayout';

export default function Rosters() {
    return (
        <PageLayout
            title='Rosters'
            description='Collections of bots to race against'
        ></PageLayout>
    )
}